'use client';

import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Loader2, ShoppingCart, CheckCircle2, X } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { Store, Product } from '@/utils/interfaces/interfaces';
import { emitCartUpdated } from '@/utils/events/cartEvents';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

interface AddToCartButtonProps {
  ID: string;
  settings?: Partial<Store>;
  product: Product;
  minimal?: boolean;
  className?: string;
}

const CATEGORIES_WITH_SIZES = ['clothing', 'shoes', 'accessories'];

export default function AddToCartButton({ ID, settings, product, minimal, className }: AddToCartButtonProps) {
  const [loading, setLoading] = useState(false);
  const [availableSizes, setAvailableSizes] = useState<{size: string, quantity: number}[]>([]);
  const [notification, setNotification] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);
  const router = useRouter();
  const [showSizeModal, setShowSizeModal] = useState(false);

  // Add initialization effect
  useEffect(() => {
    // Initialization logic
  }, []);

  useEffect(() => {
    // Logic for when showSizeModal changes
  }, [showSizeModal]);

  useEffect(() => {
    if (product.size_inventory) {
      const inStockSizes = product.size_inventory.filter(si => si.quantity > 0);
      setAvailableSizes(inStockSizes);
    }
  }, [product.size_inventory]);

  const showNotification = (type: 'success' | 'error', message: string) => {
    setNotification({ type, message });
    setTimeout(() => setNotification(null), 3000);
  };

  if (!product.stock || (CATEGORIES_WITH_SIZES.includes(product.category?.toLowerCase() || '') && availableSizes.length === 0)) {
    return null;
  }

  const addToCart = async (sizeToUse?: string) => {
    if (product.category && CATEGORIES_WITH_SIZES.includes(product.category.toLowerCase())) {
      if (!sizeToUse) {
        setShowSizeModal(true);
        return;
      }

      const sizeStock = availableSizes.find(s => s.size === sizeToUse);
      if (!sizeStock || sizeStock.quantity <= 0) {
        showNotification('error', 'Selected size is out of stock');
        return;
      }
    } else if (product.stock <= 0) {
      showNotification('error', 'Product is out of stock');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch('/api/cart/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          productId: ID,
          quantity: 1,
          size: sizeToUse || undefined,
        }),
      });

      if (response.status === 401) {
        router.push('/login');
        return;
      }

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to add to cart');
      }

      showNotification('success', 'Item has been added to your cart successfully');
      emitCartUpdated();
      router.refresh();
    } catch (error) {
      showNotification('error', error instanceof Error ? error.message : 'Failed to add item to cart');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      {notification && (
        <div
          style={{ 
            backgroundColor: notification.type === 'success' 
              ? `${settings?.secondary_color}1A`
              : '#FEE2E2'
          }}
          className="absolute bottom-full left-10 right-10 mb-2 p-2 rounded-md shadow-sm"
        >
          <div className="flex items-center justify-start gap-1.5">
            {notification.type === 'success' ? (
              <CheckCircle2 style={{ color: settings?.primary_color }} className="h-5 w-5 flex-shrink-0" />
            ) : (
              <X className="h-5 w-5 flex-shrink-0 text-red-500" />
            )}
            <span 
              className="text-sm"
              style={{ 
                color: notification.type === 'success' 
                  ? settings?.font_color 
                  : '#B91C1C'
              }}
            >
              {notification.message}
            </span>
          </div>
        </div>
      )}

      <Dialog 
        open={showSizeModal} 
        onOpenChange={(open) => {
          setShowSizeModal(open);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Select Size</DialogTitle>
          </DialogHeader>
          <div className="grid grid-cols-2 gap-2">
            {availableSizes.map((sizeInfo) => (
              <Button
                key={sizeInfo.size}
                onClick={() => {
                  setShowSizeModal(false);
                  addToCart(sizeInfo.size);
                }}
                variant="outline"
              >
                {sizeInfo.size} ({sizeInfo.quantity} available)
              </Button>
            ))}
          </div>
        </DialogContent>
      </Dialog>

      <Button
        onClick={() => {
          addToCart();
        }}
        disabled={loading}
        style={{ 
          backgroundColor: settings?.primary_color,
          '--hover-color': `${settings?.primary_color}E6`
        } as React.CSSProperties}
        className={`${className} rounded-full p-2 bg-primary text-white hover:bg-primary/90`}
      >
        {loading ? (
          <Loader2 className="h-4 w-4 animate-spin" />
        ) : minimal ? (
          <span className="text-xl">+</span>
        ) : (
          <>
            <ShoppingCart className="mr-2 h-4 w-4" />
            Add to Cart
          </>
        )}
      </Button>
    </div>
  );
}
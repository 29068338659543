'use client';
import { Card } from "@/components/ui/cards";
import { Product, Store } from '@/utils/interfaces/interfaces';
import AddToCartButton from './AddToCartButton';

interface ProductCardProps {
  ID: string;
  product: Product;
  children: React.ReactNode;
  className?: string;
  settings?: Partial<Store>;
}

const CATEGORIES_WITH_SIZES = ['clothing', 'shoes', 'accessories'];

export function ProductCard({ ID, product, children, className, settings }: ProductCardProps) {
  const shouldDisplayProduct = (product: Product) => {
    if (!product.active || product.status !== 'published') return false;
    
    // Check if it's a sized product
    if (CATEGORIES_WITH_SIZES.includes(product?.category?.toLowerCase() || '')) {
      return product?.size_inventory?.some(si => si.quantity > 0) ?? false;
    }
    
    // Regular product stock check
    return product.stock > 0;
  };

  if (!shouldDisplayProduct(product)) {
    return null;
  }

  return (
    <Card 
      className={`${className} relative flex flex-col ${
        settings?.background_color ? `bg-[${settings.background_color}]` : ''
      } ${
        settings?.font_color ? `text-[${settings.font_color}]` : ''
      }`}
    >
      {children}
      <div className="p-6 mt-auto">
        {CATEGORIES_WITH_SIZES.includes(product?.category?.toLowerCase() || '') && (
          <div className="mb-4">
            <span className="block text-sm font-medium">Available Sizes:</span>
            <ul className="flex gap-2">
              {product.size_inventory?.map((sizeItem, index) => (
                sizeItem.quantity > 0 && (
                  <li key={index} className="px-2 py-1 bg-gray-200 rounded">
                    {sizeItem.size}
                  </li>
                )
              ))}
            </ul>
          </div>
        )}

        <div className="absolute bottom-4 right-4">
          <AddToCartButton 
            ID={ID}
            product={product}
            settings={settings}
            className="md:block hidden"
          />
          <AddToCartButton 
            ID={ID}
            product={product}
            settings={settings}
            minimal={true}
            className="md:hidden block"
          />
        </div>
      </div>
    </Card>
  );
}